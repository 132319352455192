$color_text: #333;
$color_primary: #2D5D84;
$color_secondary: #999;
$color_danger: #850707;
$color_border: #ebebeb;
$color_disabled: #ebebeb;

$border_radius: 0;
$border_width: 1px;

$padding_top_bottom: 5px;
$padding_left_right: 10px;

$font_size_floating_label: 12px;

$font__size_tab_heading: 20px;
$font__size_modal_heading: 24px;