.linq-form {
    position: relative;

    .response-message {
        margin-bottom: 5px!important;
    }

    .results-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: -1;
        line-height: 1.7;
        color: $color_text;
        @include transition(opacity 300ms ease-in-out);

        &.active {
            opacity: 1;
            z-index: 2;
            background-color: rgba(255, 255, 255, .8);
            @include transition(opacity 300ms ease-in-out);

            .inner {
                opacity: 0;
                z-index: -1;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: 0 auto;
                max-width: 480px;
                padding: 30px 15px;
                @include transform(translateY(-50%));

                &.active {
                    opacity: 1;
                    z-index: 3;
                }
            }
        }

        .loader {
            @extend .spinner--cube; // from ./animations.scss
        }

        .reset-btn {
            cursor: pointer!important;
            font-size: 12px;
            font-weight: 600;
            margin: 15px auto 0;
            padding: 8px;
            width: 200px;
            color: #2D5D84;
            border: 1px solid #2D5D84;
            text-transform: uppercase;
            background-color: #FFF;
            -webkit-transition: all 300ms;
            -moz-transition: all 300ms;
            transition: all 300ms;
        }
    }

    .waiting-box {
        @extend .results-box;
    }

    .response-box {
        @extend .results-box;
    }

    form {
        line-height: 43px;

        input,
        textarea,
        select {
            margin: 0;
            width: 100%;
            box-shadow: none;
            font-weight: normal;
            color: $color_text;
            border: $border_width solid;
            border-color: $color_text;
            border-radius: $border_radius;
            padding: $padding_top_bottom $padding_left_right;
        }

        label {
            margin-bottom: 0;
            font-weight: 300;
            color: $color_text;
            padding: ($padding_top_bottom + $border_width) ($padding_left_right + $border_width);
        }

        textarea {
            resize: none;
        }

        button {
            color: #FFF;
            background-color: #B50A0A;
            border: none;
            font-size: 20px;
            padding: 18px 30px 16px;
            line-height: 23px;
            font-weight: 300;
            border-radius: 10px;
            @include transition(all 300ms ease-in-out);

            @include hover-state {
                background-color: #850707;
                @include transition(all 300ms ease-in-out);
            }
        }

        .align-right {
            text-align: right;
        }

        .form-block {

            &.left-side {
                float: left;
                width: 50%;
                padding-right: 5px;
            }

            &.right-side {
                float: left;
                width: 50%;
                padding-left: 5px;
            }

            &.center-side {
                clear: both;
            }

            .linq-form-row {
                padding-bottom: 10px;
            }
        }

        .linq-form-field {

            &.floating-placeholder {
                position: relative;

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include transition(all 300ms ease-in-out);
                }

                &.linq-form-field-float {

                    label {
                        font-size: $font_size_floating_label;
                        padding-top: 0;
                        padding-bottom: 0;
                        line-height: 22px;
                    }
                }
            }

            &.hasError {

                label {
                    color: $color_danger;
                }

                input, textarea, select {
                    border: 1px solid;
                    border-color: $color_danger;
                }
            }
        }

        .field-radio {

            .input-radio {
                cursor: pointer;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: inline-block;
                position: relative;
                top: 0;
                height: 20px;
                width: 20px;
                border: 0;
                border-radius: 50px;
                margin-right: 7px;
                outline: none;
                border: 1px solid $color_border;
                @include transition(all 300ms ease-in-out);
            }

            .input-radio:checked::before {
                content: '\02143';
                position: absolute;
                left: 6px;
                top: -2px;
                @include transform(rotate(40deg));
            }

            .input-radio:hover {
                background-color: $color_secondary;
                @include transition(all 300ms ease-in-out);
            }

            .input-radio:checked {
                background-color: $color_secondary;
            }

            label {
                cursor: pointer;
                padding: 0 5px;
                vertical-align: top;
            } 
        }
    }

    @media screen and (max-width: 480px) {

        form {

            .form-block {

                &.left-side,
                &.right-side {
                    float: none;
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}


.linq-form {

    form.footer-form {

        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }

        label {
            color: #FFF;
        }

        input,
        textarea,
        select {
            background-color: rgba(255,255,255,0.3);
            border: 1px solid #FFF;
            color: #FFF;
        }

        .center-side.form-block {

            label[for="address16"],
            label[for="message16"] {
                line-height: 1.7;
            }

            @media screen and (min-width: 568px) {

                .linq-form-row:nth-child(2),
                .linq-form-row:nth-child(3),
                .linq-form-row:nth-child(4),
                .linq-form-row:nth-child(5) {
                    float: left;
                    width: 50%;
                }

                .linq-form-row:nth-child(2),
                .linq-form-row:nth-child(4) {
                    padding-right: 5px;
                }
                
                .linq-form-row:nth-child(3),
                .linq-form-row:nth-child(5) {
                    padding-left: 5px;
                }

                .linq-form-row:nth-child(6) {
                    clear: both;
                }
            }
        }
    }

    form.contact-form {

        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }

        .center-side.form-block {

            label[for="address16"],
            label[for="message16"] {
                line-height: 1.7;
            }

            @media screen and (min-width: 568px) {

                .linq-form-row:nth-child(2),
                .linq-form-row:nth-child(3),
                .linq-form-row:nth-child(4),
                .linq-form-row:nth-child(5) {
                    float: left;
                    width: 50%;
                }

                .linq-form-row:nth-child(2),
                .linq-form-row:nth-child(4) {
                    padding-right: 5px;
                }
                
                .linq-form-row:nth-child(3),
                .linq-form-row:nth-child(5) {
                    padding-left: 5px;
                }

                .linq-form-row:nth-child(6) {
                    clear: both;
                }
            }
        }
    }

    form.vendor-form {

        .center-side.form-block {

            label[for="coverage_area18"],
            label[for="company_provide18"],
            label[for="certifications_licenses18"] {
                line-height: 1.7;
            }

            @media screen and (min-width: 568px) {

                .linq-form-row:nth-child(2),
                .linq-form-row:nth-child(3),
                .linq-form-row:nth-child(4),
                .linq-form-row:nth-child(5) {
                    float: left;
                    width: 50%;
                }

                .linq-form-row:nth-child(2),
                .linq-form-row:nth-child(4) {
                    padding-right: 5px;
                }
                
                .linq-form-row:nth-child(3),
                .linq-form-row:nth-child(5) {
                    padding-left: 5px;
                }

                .linq-form-row:nth-child(6) {
                    clear: both;
                }
            }

            .linq-inline {
                line-height: 1.7;

                input {
                    display: inline-block;
                    position: relative;
                    top: -2px;
                    width: auto;
                    margin-right: 10px;
                }
            }

            .hasError .ven-label {
                color: $color_danger;
            }

            .vendor-form-text {
                font-size: 15px;
                margin-top: 20px;
            }
        }
    }
}