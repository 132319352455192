@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin animation($transition...) {
    -webkit-animation: $transition;
    animation: $transition;
}

@mixin hover-state {
    &:hover, &:active, &:focus {
        @content;
    }
}